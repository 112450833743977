/* globals gettext */
import { h, Fragment } from "preact"
import { useEffect, useState, useRef } from "preact/hooks"
import getCookie from "./utils/getCookie"

export const MembershipForm = ({ endpoint, formEl, fieldsData }) => {
  const [fieldsAndErrorData, setFieldsAndErrorData] = useState(fieldsData)
  const [affected, setAffected] = useState("")
  const [professional, setProfessional] = useState("")
  const [parents, setParents] = useState("")
  const [category, setCategory] = useState("")
  const [surroundings, setSurroundings] = useState("")

  const [state, setState] = useState("")
  const [message, setMessage] = useState("")

  const setErrors = (errors) => {
    const newFieldsAndErrorData = { ...fieldsAndErrorData }
    Object.keys(fieldsAndErrorData).forEach((k) => {
      newFieldsAndErrorData[k]["errors"] = errors[k] || ""
    })
    setFieldsAndErrorData(newFieldsAndErrorData)
  }

  const privacyPolicyRef = useRef()

  useEffect(() => {
    if (privacyPolicyRef?.current)
      privacyPolicyRef.current.innerHTML = gettext(
        "membership_form_privacy_policy_message",
      )
    formEl.onsubmit = async (e) => {
      e.preventDefault()
      setState("pending")

      const response = await postData(new FormData(e.target))

      if (response.state === "success") {
        setState("success")
        setMessage(response.message)
      } else if (response.state === "error") {
        setState("")
        setErrors(response.errors)
        setMessage("")
      } else {
        setState("fatal")
        setMessage(response.message || "Unexpected error occurred.")
        console.log(response)
      }
    }
  }, [])

  useEffect(() => {
    const firstError = formEl.querySelector(".form-error")
    if (firstError) firstError.scrollIntoView({ behavior: "smooth" })
  }, [fieldsAndErrorData])

  useEffect(() => {
    const successMessage = formEl.querySelector(".success-message")
    if (successMessage) successMessage.scrollIntoView({ behavior: "smooth" })
  }, [message])

  const postData = async (data) => {
    const response = await fetch(endpoint, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: data,
    })

    return response.json()
  }

  return (
    <>
      {(state === "" || state === "pending") && (
        <>
          {/* common fields */}
          <SelectField name="salutation" {...fieldsAndErrorData.salutation} />
          <CharField name="lastname" {...fieldsAndErrorData.lastname} />
          <CharField name="firstname" {...fieldsAndErrorData.firstname} />
          <CharField name="address" {...fieldsAndErrorData.address} />
          <CharField name="postal_code" {...fieldsAndErrorData.postal_code} />
          <CharField name="location" {...fieldsAndErrorData.location} />
          <SelectField name="country" {...fieldsAndErrorData.country} />
          <CharField name="phone" {...fieldsAndErrorData.phone} />
          <CharField name="email" {...fieldsAndErrorData.email} />
          <SelectField
            name="category"
            {...fieldsAndErrorData.category}
            setValue={setCategory}
          />
          {category === "single" && (
            <>
              <SelectField
                name="professional"
                {...fieldsAndErrorData.professional}
                setValue={setProfessional}
                required={true}
              />
            </>
          )}
          {category === "single" && professional === "no" && (
            <>
              <SelectField
                name="affected"
                {...fieldsAndErrorData.affected}
                setValue={setAffected}
                required={true}
              />
            </>
          )}
          {category === "single" && affected === "no" && (
            <SelectField
              name="parents"
              {...fieldsAndErrorData.parents}
              setValue={setParents}
              required={true}
            />
          )}
          {category === "single" && affected === "yes" && (
            <DateField
              name="date_of_birth"
              {...fieldsAndErrorData.date_of_birth}
            />
          )}
          {category === "single" && affected === "no" && parents === "yes" && (
            <>
              <CharField
                name="child_name"
                {...fieldsAndErrorData.child_name}
                required={true}
              />
              <DateField
                name="child_date_of_birth"
                {...fieldsAndErrorData.child_date_of_birth}
                required={true}
              />
              <SelectField
                name="child_gender"
                {...fieldsAndErrorData.child_gender}
                required={true}
              />
              <CharField
                name="child_2_name"
                {...fieldsAndErrorData.child_2_name}
              />
              <DateField
                name="child_2_date_of_birth"
                {...fieldsAndErrorData.child_2_date_of_birth}
              />
              <SelectField
                name="child_2_gender"
                {...fieldsAndErrorData.child_2_gender}
              />
            </>
          )}
          {category === "single" && affected === "no" && parents === "no" && (
            <SelectField
              name="surroundings"
              {...fieldsAndErrorData.surroundings}
              setValue={setSurroundings}
            />
          )}
          {category === "collective" && (
            <>
              <CharField
                name="institution_name"
                {...fieldsAndErrorData.institution_name}
              />
              <CharField
                name="institution_function"
                {...fieldsAndErrorData.institution_function}
              />
            </>
          )}
          {category && (
            <TextField name="comment" {...fieldsAndErrorData.comment} />
          )}
          {category === "single" &&
            affected === "no" &&
            parents === "no" &&
            surroundings === "no" && (
              <div class="form-message">
                {gettext("'Please donate' message")}
              </div>
            )}
          <p ref={privacyPolicyRef} />
          <div class="form-group__next">
            <button
              type="submit"
              class="button no-decoration button--primary"
              value={gettext("Send")}
              disabled={
                state === "pending" ||
                (category === "single" &&
                  affected === "no" &&
                  parents === "no" &&
                  surroundings === "no")
              }
            >
              <span>{gettext("Send")}</span>
            </button>
          </div>
        </>
      )}
      {message && <div class="success-message">{message}</div>}
    </>
  )
}

const CharField = (data) => {
  return (
    <div class="form-group">
      <fieldset class="form-fieldset">
        <Label {...data} />
        <input
          type="text"
          name={data.name}
          required={data.required}
          placeholder={data.label}
        />
      </fieldset>
    </div>
  )
}

const TextField = (data) => {
  return (
    <div class="form-group">
      <fieldset class="form-fieldset">
        <Label {...data} />
        <textarea
          name={data.name}
          required={data.required}
          placeholder={data.label}
        />
      </fieldset>
    </div>
  )
}

const SelectField = (data) => {
  return (
    <div class="form-group">
      <fieldset class="form-fieldset">
        <Label {...data} />
        <select
          name={data.name}
          onChange={
            data.setValue
              ? (e) => {
                  data.setValue(e.target.value)
                }
              : () => {}
          }
          required={data.required}
        >
          {data.choices.map((c, i) => {
            return (
              <option key={i} value={c[0]} selected={c[0] === "CH"}>
                {c[1]}
              </option>
            )
          })}
        </select>
      </fieldset>
    </div>
  )
}

const DateField = (data) => {
  return (
    <div class="form-group">
      <fieldset class="form-fieldset">
        <Label {...data} />
        <input type="date" name={data.name} />
      </fieldset>
    </div>
  )
}

const Label = (data) => {
  return (
    <>
      <label for={data.name}>
        {data.label}
        {data.required && "*"}
      </label>
      {data.errors && <div class="form-error">{data.errors.join(" ")}</div>}
    </>
  )
}
