export const initRegions = () => {
  const regionsMap = document.querySelector(".section--region")

  if (regionsMap) {
    const labels = Array.from(
      regionsMap.querySelectorAll(".regions__overview__link"),
    )
    const svgGroups = Array.from(regionsMap.querySelectorAll("svg g"))

    const regions = labels.map((label) => {
      const code = label.dataset.toggle
      return {
        code,
        label,
        svgGroup: svgGroups.find((g) => g.dataset.toggle === code),
      }
    })

    regions.forEach((region) => {
      if (region.svgGroup) {
        const mouseEnterHandler = () => {
          region.svgGroup.classList.add("hover")
        }

        const mouseLeaveHandler = () => {
          region.svgGroup.classList.remove("hover")
        }

        region.label.addEventListener("mouseenter", mouseEnterHandler)
        region.label.addEventListener("mouseleave", mouseLeaveHandler)

        region.svgGroup.addEventListener("click", (e) => {
          e.preventDefault()
          window.location.href = region.label.href
        })
      }
    })
  }
}
