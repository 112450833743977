/* globals gettext  */
export function initBacklinks() {
  const referrerSameOrigin =
      document.referrer &&
      document.referrer.indexOf(window.location.hostname) < 10,
    backlinks = document.querySelectorAll(".backlink")

  backlinks.forEach((backlink) => {
    if (referrerSameOrigin) {
      backlink.querySelector("span").textContent = gettext("back")
      backlink.onclick = (e) => {
        e.preventDefault()
        history.back()
      }
    }
    backlink.classList.remove("hide")
  })
}
