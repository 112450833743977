import "./main.scss"
import { Gallery } from "./js/gallery"
import { ToggleElements } from "./js/toggleElements"
import { MenuHandler } from "./js/menuHandler"
import { Navigation } from "./js/navigation"
import { Masonry } from "./js/masonry"
import _throttle from "lodash/throttle"
import { MembershipForm } from "./js/membershipForm"
import { ApplicationForm } from "./js/applicationForm"
import { render, h } from "preact"
import { initRegions } from "./js/regions"
import { initBacklinks } from "./js/utils/backlink"

const $ = window.Bliss
const $$ = $.$

$.ready().then(() => {
  // Menu Handler

  const menuOverlay = $("[data-overlay-wrapper]")

  if (menuOverlay) {
    new MenuHandler(menuOverlay, window.__menu)
  }

  $$("[data-masonry]").forEach(
    (el) => new Masonry(el, el.getAttribute("data-masonry")),
  )

  // Mobile Nav Toggle
  new Navigation()

  new ToggleElements()

  initRegions()

  const scrolledThreshold = 120

  window.addEventListener(
    "scroll",
    _throttle(() => {
      if (window.pageYOffset > scrolledThreshold) {
        document.body.classList.add("scrolled")
        document.body.classList.remove("scrolled--top")
      } else {
        document.body.classList.remove("scrolled")
        document.body.classList.add("scrolled--top")
      }
    }, 100),
  )

  const galleries = $$("[data-gallery]")
  if (galleries) {
    galleries.forEach((e) => {
      new Gallery(e, {
        interval: 5000,
        autoPlay: true,
        createThumbs: true,
        endless: false,
        visibleSlides: 1,
        hasSingleNum: true,
        hasProgressbar: true,
        hasVisibleSlidesNum: false,
      })
    })
  }
  const moodboards = $$("[data-moodboard]")
  if (moodboards) {
    moodboards.forEach((e) => {
      new Gallery(e, {
        interval: 5000,
        autoPlay: true,
        createThumbs: true,
        endless: false,
        visibleSlides: 1,
        hasSingleNum: false,
        hasProgressbar: true,
        hasVisibleSlidesNum: false,
      })
    })
  }

  const factsheets = $$(".factsheets__slider")

  if (factsheets) {
    const mediaQuerySmall = window.matchMedia("(max-width: 750px)")
    const mediaQueryMediumMin = window.matchMedia("(min-width: 750px)")
    const mediaQueryMediumMax = window.matchMedia("(max-width: 1280px)")
    let _visibleSlides = 4

    window.addEventListener("resize", () => {
      if (mediaQueryMediumMin.matches && mediaQueryMediumMax.matches) {
        _visibleSlides = 2
        factsheets.forEach((e) => {
          new Gallery(e, {
            interval: 5000,
            autoPlay: false,
            createThumbs: false,
            endless: false,
            visibleSlides: _visibleSlides,
            hasVisibleSlidesNum: true,
          })
        })
      } else if (mediaQuerySmall.matches && !mediaQueryMediumMin.matches) {
        _visibleSlides = 1
        factsheets.forEach((e) => {
          new Gallery(e, {
            interval: 5000,
            autoPlay: false,
            createThumbs: false,
            endless: false,
            visibleSlides: _visibleSlides,
            hasVisibleSlidesNum: true,
          })
        })
      } else {
        _visibleSlides = 4
        factsheets.forEach((e) => {
          new Gallery(e, {
            interval: 5000,
            autoPlay: false,
            createThumbs: false,
            endless: false,
            visibleSlides: _visibleSlides,
            hasVisibleSlidesNum: true,
          })
        })
      }
    })

    if (mediaQueryMediumMin.matches && mediaQueryMediumMax.matches) {
      _visibleSlides = 2
      factsheets.forEach((e) => {
        new Gallery(e, {
          interval: 5000,
          autoPlay: false,
          createThumbs: false,
          endless: false,
          visibleSlides: _visibleSlides,
          hasVisibleSlidesNum: true,
        })
      })
    } else if (mediaQuerySmall.matches && !mediaQueryMediumMin.matches) {
      _visibleSlides = 1
      factsheets.forEach((e) => {
        new Gallery(e, {
          interval: 5000,
          autoPlay: false,
          createThumbs: false,
          endless: false,
          visibleSlides: _visibleSlides,
          hasVisibleSlidesNum: true,
        })
      })
    } else {
      _visibleSlides = 4
      factsheets.forEach((e) => {
        new Gallery(e, {
          interval: 5000,
          autoPlay: false,
          createThumbs: false,
          endless: false,
          visibleSlides: _visibleSlides,
          hasVisibleSlidesNum: true,
        })
      })
    }
  }

  // article lists
  function articlesMore(el) {
    el.textContent = "..."
    fetch(el.dataset.articlesMore)
      .then((response) => response.text())
      .then((html) => {
        el.parentElement.outerHTML = html
      })
  }
  window.addEventListener("click", (e) => {
    const target = e.target.closest("[data-articles-more]")
    if (target) articlesMore(target)
  })

  // add membership form
  const membershipFormEl = document.querySelector("#membership-form")
  const membershipWrapperEl = document.querySelector("#membership-form-wrapper")
  const membershipFieldsEl = document.querySelector(
    "#membership-form-fields-data",
  )
  if (membershipFormEl && membershipFieldsEl && membershipWrapperEl) {
    render(
      <MembershipForm
        endpoint={membershipFormEl.dataset.endpoint}
        formEl={membershipFormEl}
        fieldsData={JSON.parse(membershipFieldsEl.textContent)}
      />,
      membershipWrapperEl,
    )
  }

  // add membership form
  const applicationFormEl = document.querySelector("#application-form")
  const applicationWrapperEl = document.querySelector(
    "#application-form-wrapper",
  )
  const applicationFieldsEl = document.querySelector(
    "#application-form-fields-data",
  )
  if (applicationFormEl && applicationFieldsEl && applicationWrapperEl) {
    const applicationtype = applicationFormEl.getAttribute("data-type")
    render(
      <ApplicationForm
        endpoint={applicationFormEl.dataset.endpoint}
        formEl={applicationFormEl}
        fieldsData={JSON.parse(applicationFieldsEl.textContent)}
        type={applicationtype}
      />,
      applicationWrapperEl,
    )
  }

  initBacklinks()
})
