import _throttle from "lodash/throttle"

const $ = window.Bliss
const $$ = $.$

export class Navigation {
  constructor() {
    $.delegate(document.body, "click", "[data-toggle-mobile-nav]", (el) => {
      el.preventDefault()
      this.el = el
      this.target =
        el.target && this.el.target.closest("[data-toggle-mobile-nav]")
      if (this.target) {
        document.body.classList.toggle("nav-is-open")
        if (document.body.classList.contains("nav-is-open")) {
          window.addEventListener("resize", () => {
            document.body.classList.remove("nav-is-open")
          })
        }
      }
    })

    $.delegate(document.body, "click", "[data-toggle]", (el) => {
      this.el = el
      this.target = el.target && this.el.target.closest("[data-toggle]")
      if (this.target) {
        this.target.classList.toggle("toggle--collapsed")
      } else {
        this.target.classList.remove("toggle--collapsed")
      }

      window.addEventListener("resize", () => {
        this.target.classList.remove("toggle--collapsed")
      })
    })
  }
}
const scrolledThreshold = 120

window.addEventListener(
  "scroll",
  _throttle(() => {
    if (window.pageYOffset > scrolledThreshold) {
      $$("[data-toggle]").forEach((el) => {
        if (el.classList.contains("toggle--collapsed")) {
          el.classList.remove("toggle--collapsed")
        }
      })
    }
  }, 100),
)
